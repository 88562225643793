@import 'vars.scss';

.news-section {
  position: relative;
  margin-top: 130px;

  @media (max-width: $mobile-width) {
    margin-top: 54px;
    padding-bottom: 80px;
  }

  .embla__prev-next-buttons {
    justify-content: flex-end;

    @media (max-width: $mobile-width) {
      display: none;
    }
  }

  .embla__slide {
    @media (max-width: $mobile-width) {
      &:first-child {
        padding-left: 0;
      }

      &:last-child {
        padding-right: 0;
      }
    }
  }

  .button {
    position: absolute;
    left: 0px;
    bottom: 0px;

    @media (max-width: $mobile-width) {
      width: 100%;
    }
  }
}
