.number {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 24px;
  top: 14px;
  box-sizing: border-box;
  width: 14px;
  height: 14px;
  background: #8CC540;
  border: 1px solid #e0feee;
  border-radius: 14px;
  font-weight: 600;
  font-size: 8px;
  color: white;
  user-select: none;

  &.bigNumber {
    padding-left: 1px;
    width: 16px;
    height: 16px;
  }
}
