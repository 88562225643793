@import 'vars.scss';

.icon {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 48px;
  height: 48px;
  transition: opacity 0.1s;
  cursor: pointer;

  svg {
    width: 24px;
    height: 24px;
  }

  &.mobile {
    display: none;
  }

  @media (max-width: $mobile-width) {
    &.desktop {
      display: none;
    }

    &.mobile {
      display: flex;
    }
  }

  &.hidden {
    opacity: 0;
  }

  &.unseen {
    svg path {
      fill: #8CC540;
    }
  }
}
