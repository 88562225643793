.reactive-carousel {
  position: relative;
  width: 100%;

  .slides {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;

    .slide {
      position: absolute;
      width: 100%;
      height: 100%;
    }
  }

  .arrow-navigation {
    display: flex;
    position: absolute;
    top: 30px;
    right: 47px;

    .chevron-left {
      margin-right: 5px;
    }
  }

  .bottom-navigation {
    display: flex;
    justify-content: center;
    margin-top: 67px;

    &.dot-navigation {
      .dot {
        display: inline-block;
        margin-left: 10px;
        width: 12px;
        height: 12px;
        background-color: #CCCCCC;
        border-radius: 6px;
        cursor: pointer;

        &.active {
          background: #72BF44;
          pointer-events: none;
        }
      }
    }
  }
}
