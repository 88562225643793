@import 'vars.scss';

.page-footer {
  display: flex;
  justify-content: center;
  margin-top: 200px;
  padding: 70px 0 66px 0;
  width: 100%;
  border-top: 1px solid #DDDDDD;
  border-bottom: 1px solid #DDDDDD;

  .wrapper {
    display: flex;
    justify-content: space-between;
    width: 1220px;

    .section {
      flex: 1;

      .title {
        margin-bottom: 15px;
        font-weight: 600;
        font-size: 16px;
        color: #72BF44;
      }

      .chevron {
        display: none;
      }

      ul {
        li {
          display: flex;
          align-items: center;
          padding: 9px 0;
          font-weight: 500;
          font-size: 14px;
          color: #444444;

          a {
            display: block;
            font-weight: 500;
            font-size: 14px;
            color: #444444;
          }
        }

        &.contact-list {
          li {
            display: flex;

            img {
              margin-right: 16px;
            }
          }
        }
      }
    }
  }

  @media (max-width: $mobile-width) {
    margin-top: 72px;
    padding: 0;
    width: calc(100% + 40px);
    border-top-width: 0px;

    .wrapper {
      flex-direction: column;
      width: 100%;

      .section {
        flex-basis: auto;
        padding-left: 20px;
        border-top: 1px solid #DCDCDC;

        &.toggleable {
          position: relative;
          flex-basis: 62px;
          overflow: hidden;
          transition: all 0.3s;

          &.expanded {
            flex-basis: auto;

            .chevron {
              transform: rotate(180deg);
            }
          }

          ul {
            margin-bottom: 16px;
          }

          .chevron {
            display: flex;
            position: absolute;
            top: 8px;
            right: 6px;
          }
        }

        .title {
          position: relative;
          z-index: 1;
          margin: 0px;
          padding: 22px 0 20px 0;
          cursor: pointer;
        }
      }
    }
  }
}
