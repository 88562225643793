@import 'vars.scss';

.feedback-section {
  margin-top: 163px;
  margin-bottom: 100px;

  .reactive-carousel {
    height: 100%;

    .slides {
      background: white;
      box-shadow: 0px 1px 35px rgba(0, 0, 0, 0.1);
      backdrop-filter: blur(10px);
      border-radius: 6px;
    }
  }

  .feedback-item {
    display: flex;
    position: relative;
    min-height: 240px;

    .profile-pic {
      height: 420px;
    }

    .content {
      position: relative;
      margin: 0 47px 30px 47px;

      .quote-background {
        position: absolute;
        top: 30px;
        left: 0px;
      }

      .text {
        margin: 94px 0 0px 0;
        font-style: italic;
        font-weight: 500;
        font-size: 18px;
        line-height: 34px;
      }

      .details {
        position: absolute;
        left: 0px;
        bottom: -30px;
        font-size: 18px;

        .name {
          font-weight: bold;
        }
      }

      .star-list {
        position: absolute;
        bottom: -30px;
        right: 0px;
      }
    }
  }

  @media (max-width: $mobile-width) {
    margin-top: 54px;
    margin-bottom: 0px;

    .reactive-carousel {
      margin: 0 auto;
      max-width: 319px;
      transition: height 0.3s;

      .arrow-navigation {
        z-index: 2;
        top: auto;
        right:  auto;
        bottom: 20px;
        box-sizing: border-box;
        padding: 0 20px;
        width: 100%;
        justify-content: space-between;
      }

      .bottom-navigation {
        position: relative;
        z-index: 1;
        margin-top: -48px;
      }
    }

    .feedback-item {
      flex-direction: column;

      .profile-pic {
        width: 100%;
        height: auto;
      }

      .content {
        margin: 0 20px 30px 20px;

        .quote-background {
          top: 20px;
          width: 43px;
        }

        .text {
          margin-top: 72px;
          font-size: 16px;
        }

        .details {
          position: static;
          margin-top: 20px;
        }

        .star-list {
          top: 28px;
          bottom: auto;

          img {
            width: 20px;
          }
        }
      }
    }
  }
}
