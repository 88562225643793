#cookie-banner {
  position: fixed;
  z-index: 999999999;
  left: 0px;
  right: 0px;
  bottom: -250px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  height: 250px;
  background-color: white;
  background-color: hsla(0, 0%, 100%, 0.9);
  animation: appear-from-the-bottom 0.8s forwards 0.8s;

  @keyframes appear-from-the-bottom {
    0% { bottom: -250px; }
    100% { bottom: 0px; }
  }

  .section {
    width: 47vw;

    &.right {
      width: 40vw;
      text-align: center;
    }

    .title {
      color: rgb(34, 34, 34);
      font-size: 17px;
    }

    .details {
      margin-top: 10px;
      color: rgb(164, 160, 160);
      font-size: 12px;

      p {
        line-height: 22px;
      }
    }

    .agree {
      color: rgb(118, 191, 68);
      font-size: 23px;
      font-weight: 700;
      cursor: pointer;

      > img {
        margin-right: 12px;
      }
    }
  }
}
