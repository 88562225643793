@import "vars.scss";

.emptyMessage {
  margin-bottom: 200px;
  margin-top: 30px;
  font-size: 16px;

  @media (max-width: $mobile-width) {
    margin-bottom: 60px;
    text-align: center;
    font-size: 14px;
  }
}
