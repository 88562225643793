.mobileNotificationDropdown {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: auto;
  z-index: 999;
  background: white;

  .header {
    position: relative;
    padding: 16px 20px;
    border-bottom: 1px solid #DCDCDC;

    .close {
      position: absolute;
      top: 4px;
      right: 4px;
    }
  }

  .menuLinks {
    padding: 10px 20px;
  }
}
