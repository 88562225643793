@import 'vars.scss';

.faq-page {
  display: flex;
  justify-content: center;

  .main-wrapper {
    display: flex;
    justify-content: center;

    .faq-page-wrapper {
      margin-top: 96px;
      width: 724px;
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      color: #444444;

      @media (max-width: $mobile-width) {
        width: 100%;
      }

      @media (max-width: $mobile-width) {
        margin-top: 14px;
      }

      h1 {
        margin-bottom: 60px;
        font-size: 36px;
        line-height: 44px;
        font-weight: bold;
        color: #222222;
        text-align: center;
      }

      .faq-page-content {
        display: flex;
        flex-direction: column;
        align-items: center;

        .background {
          margin-top: 40px;
          max-width: 312px;

          @media (max-width: $mobile-width) {
            margin-top: 16px;
            max-width: 100%;
          }
        }

        .accordion {
          @media (max-width: $mobile-width) {
            width: 100%;
          }
        }

        .additional-question {
          display: flex;
          flex-direction: column;
          align-items: center;
          margin-top: 26px;
          box-sizing: border-box;
          padding: 30px 12px 24px 12px;
          width: 100%;
          background: #FFFFFF;
          box-shadow: 0px 8px 30px rgba(27, 143, 39, 0.15);
          border-radius: 6px;

          @media (max-width: $mobile-width) {
            margin-top: -20px;
          }

          .title {
            font-weight: bold;
            font-size: 20px;
            line-height: 36px;
            color: #444444;
            text-align: center;

            @media (max-width: $mobile-width) {
              font-size: 18px;
              line-height: 26px;
            }
          }

          .text {
            margin-top: 4px;
            font-weight: 600;
            font-size: 14px;
            line-height: 18px;
            text-align: center;
            color: #444444;

            @media (max-width: $mobile-width) {
              margin-top: 14px;
            }
          }

          .kredy-button {
            margin-top: 27px;

            @media (max-width: $mobile-width) {
              margin-top: 14px;
            }
          }
        }
      }
    }
  }
}
