@import 'vars.scss';

.field-indicator-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  right: 0;
  height: 45px;
  width: 45px;

  &.error-icon {
    border-left: 1px solid $error-color;
  }

  &.success-icon {
    border-left: 1px solid $success-color;

    img {
      width: 16px;
    }
  }

  img {
    width: 22px;
  }
}
