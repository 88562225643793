@import 'vars.scss';

.popup-overlay {
  .popup.offer-sent-popup {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 24px 36px 39px 36px;

    @media (max-width: $mobile-width) {
      padding: 24px 20px 39px 20px;
    }

    .image {
      width: 225px;
    }

    .details {
      margin-top: 35px;
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      text-align: center;
    }

    .kredy-button {
      margin-top: 35px;
    }
  }

}
