@import 'vars.scss';

.static-page {
  .static-page-wrapper {
    margin-top: 96px;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: #444444;
    overflow: auto;

    @media (max-width: $mobile-width) {
      margin-top: 14px;
    }

    h1 {
      margin-bottom: 60px;
      font-size: 36px;
      line-height: 44px;
      font-weight: bold;
      color: #222222;
      text-align: center;

      p {
        line-height: 40px;
      }

      a {
        color: black;
      }

      @media (max-width: $mobile-width) {
        font-size: 24px;

        p {
          line-height: 32px;
        }
      }
    }

    h2 {
      margin: 36px 0 16px 0;
      font-size: 30px;
      line-height: 36px;
      font-weight: bold;
      color: #444444;

      @media (max-width: $mobile-width) {
        font-size: 18px;
        line-height: 26px;
      }
    }

    h3 {
      margin: 36px 0 16px 0;
      font-size: 24px;
      line-height: 36px;
      font-weight: bold;
      color: #444444;

      @media (max-width: $mobile-width) {
        font-size: 16px;
        line-height: 26px;
      }
    }

    h4 {
      margin: 36px 0 16px 0;
      font-size: 20px;
      line-height: 36px;
      font-weight: bold;
      color: #444444;

      @media (max-width: $mobile-width) {
        font-size: 14px;
        line-height: 26px;
      }
    }

    h5, h6 {
      margin: 36px 0 16px 0;
      font-size: 18px;
      line-height: 36px;
      font-weight: bold;
      color: #444444;

      @media (max-width: $mobile-width) {
        font-size: 12px;
        line-height: 26px;
      }
    }

    a {
      color: #72BF44;
      text-decoration: underline;

      &:hover {
        text-decoration: none;
      }
    }

    p {
      margin-bottom: 16px;
      line-height: 24px;
    }

    ul, ol {
      margin-left: 36px;
      list-style-type: disc;

      @media (max-width: $mobile-width) {
        margin-left: 24px;
      }
    }

    ol {
      margin-left: 36px;
      counter-reset: item;

      @media (max-width: $mobile-width) {
        margin-left: 24px;
      }
    }

    ol li {
      display: block
    }

    ol li:before {
      content: counters(item, ".") " ";
      counter-increment: item
    }

    table {
      margin-bottom: 32px;
      table-layout: fixed;
      border-collapse: collapse;

      tr {
        margin: 0;
        padding: 0;
      }

      td, th {
        padding: 12px;
        min-width: 150px;
        border: 1px solid black;

        @media (max-width: $mobile-width) {
          min-width: 10px;
        }

        p {
          margin: 0;
        }
      }
    }
  }
}
