@import 'vars.scss';

.news-summary {
  .cover-image {
    display: flex;
    width: 100%;
    height: 210px;
    overflow: hidden;

    @media (max-width: $mobile-width) {
      width: 100%;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .single-item-content {
    margin-top: 16px;
    padding: 0 24px;

    @media (max-width: $mobile-width) {
      margin-top: 8px;
      padding: 0 12px;
    }

    .date {
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      color: #777777;

      @media (max-width: $mobile-width) {
        font-size: 9px;
        line-height: 13px;
      }
    }

    .title {
      margin-top: 8px;

      @media (max-width: $mobile-width) {
        margin-top: 4px;
      }

      a {
        display: inline-block;
        font-weight: bold;
        font-size: 16px;
        line-height: 20px;
        color: #073327;

        @media (max-width: $mobile-width) {
          font-size: 14px;
          line-height: 16px;
        }
      }
    }

    .summary {
      margin-top: 44px;
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      color: #444444;

      @media (max-width: $mobile-width) {
        margin-top: 18px;
        font-size: 11px;
        line-height: 15px;
      }
    }

    .link {
      display: flex;
      align-items: center;
      margin-top: 12px;
      font-weight: 600;
      font-size: 14px;
      line-height: 18px;
      color: #72BF44;
      text-decoration: none;

      > img {
        margin-left: 10px;
      }

      @media (max-width: $mobile-width) {
        font-size: 9px;
        line-height: 12px;

        > img {
          margin-left: 4px;
          height: 6px;
        }
      }
    }
  }
}
