.or-separator {
  display: flex;
  align-items: center;
  margin-top: 24px;
  color: #777777;
  font-weight: 500;
  font-size: 12px;

  &::before {
    content: '';
    display: inline-block;
    width: 45%;
    height: 1px;
    background: #DDDDDD;
  }

  span {
    display: inline-block;
    width: 10%;
    text-align: center;
  }

  &::after {
    content: '';
    display: inline-block;
    width: 45%;
    height: 1px;
    background: #DDDDDD;
  }
}
