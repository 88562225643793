$slider-box-mobile-width: 600px;

.input-bound-slider {
  .input-row {
    span {
      font-weight: 500;
      font-size: 18px
    }

    input {
      margin: 8px;
      padding: 0;
      width: 144px;
      height: 45px;
      line-height: 45px;
      border: 1px solid #DDDDDD;
      border-radius: 6px;
      outline: none;
      color: #72BF44;
      font-weight: 600;
      font-size: 30px;
      text-align: center;

      /* Chrome, Safari, Edge, Opera */
      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      /* Firefox */
      &[type=number] {
        -moz-appearance: textfield;
      }

      &:focus {
        border-color: #76BF44;
      }
    }

    @media (max-width: $slider-box-mobile-width) {
      display: flex;
      justify-content: center;
    }

    @media (max-width: 319px) {
      span {
        font-size: 14px;
      }

      input {
        width: 100px;
        font-size: 20px;

        &.year-slider {
          .input-row input {
            width: 52px;
          }
        }
      }
    }
  }

  &.year-slider {
    .input-row input {
      width: 72px;
    }
  }
}
