@import 'vars.scss';

.logo {
  img {
    &:first-child {
      margin-right: 15px;
    }
  }

  @media (max-width: $mobile-width) {
    &:not(.big) {
      img {
        &:first-child {
          margin-right: 9px;
          width: 30px;
        }
        &:last-child {
          width: 57px;
        }
      }
    }
  }
}
