.social-share-buttons {
  display: flex;
  align-items: center;

  .text {
    margin-right: 30px;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
  }

  .social-icons {
    display: flex;
    align-items: center;

    li {
      display: flex;
      align-items: center;
      margin-left: 30px;

      button, a {
        cursor: pointer;
      }
    }
  }
}
