@import "vars.scss";

.action {
  display: flex;
  align-items: center;
  margin-top: 30px;
  margin-left: 22px;
  height: 46px;
  cursor: pointer;

  @media (max-width: $mobile-width) {
    margin-top: 20px;
    height: auto;
  }

  &.inactive {
    pointer-events: none;
  }

  &:hover {
    .text {
      color: #76BF44;
    }
  }

  svg path {
    fill: #76BF44;
  }

  .text {
    margin-left: 10px;
  }
}
