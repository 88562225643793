.arrow-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 44px;
  height: 44px;
  border: 1px solid #72BF44;
  border-radius: 6px;
  cursor: pointer;
  user-select: none;

  &:not(.disabled) {
    &:hover {
      background: linear-gradient(90deg, #16B14B 0.01%, #55B848 24.47%, #76BF44 53.41%, #86C341 79.49%, #8CC540 100.04%);
    }
    &:active {
      background: linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), linear-gradient(90deg, #8CC540 0.01%, #86C341 27.1%, #76BF44 53.41%, #55B848 82.32%, #16B14B 100.04%);
    }
  }

  &.disabled {
    border-color: #CCCCCC;
    pointer-events: none;
  }
}
