@import "vars.scss";

.side-menu-page {
  .page-wrapper {
    display: flex;
    margin-top: 70px;

    @media (max-width: $mobile-width) {
      margin-top: 42px;
    }

    .menu {
      margin-right: 60px;
      min-width: 190px;

      @media (max-width: $mobile-width) {
        display: none;
      }

      .user-link-list {
        li {
          display: flex;
          align-items: center;
          position: relative;
          padding-left: 20px;
          height: 36px;
          font-weight: 500;
          font-size: 14px;

          &.active {
            &::before {
              content: ' ';
              position: absolute;
              top: 1px;
              bottom: 1px;
              left: 0px;
              width: 4px;
              background-color: #72BF44;
              border-radius: 0px 3px 3px 0px;
            }

            a {
              color: #72BF44;
              font-weight: bold;
            }
          }

          .icon {
            margin-right: 10px;
          }
        }
      }
    }

    .main {
      flex: 1;

      h1 {
        font-size: 36px;

        @media (max-width: $mobile-width) {
          font-size: 24px;
          text-align: center;
        }
      }
    }
  }
}
