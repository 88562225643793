$slider-box-mobile-width: 600px;

.slider-box-tab-content {
  padding: 30px 36px 36px 36px;

  &.hidden {
    display: none;
  }

  .header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;

    @media (max-width: $slider-box-mobile-width) {
      flex-direction: column;
      align-items: center;
    }
  }

  .title {
    font-weight: bold;
    font-size: 24px;
    color: #333333;

    @media (max-width: $slider-box-mobile-width) {
      margin-bottom: 16px;
      font-size: 20px;
    }
  }

  .year-slider {
    margin-top: 36px;
  }

  .button-wrapper {
    display: flex;
    justify-content: flex-end;
    margin-top: 63px;
  }

  @media (max-width: $slider-box-mobile-width) {
    padding: 20px;

    .button-wrapper {
      margin-top: 36px;

      .kredy-button {
        width: 100%;
      }
    }
  }
}
