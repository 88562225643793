@import 'vars.scss';

.register-with-email {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 230px;

  @media (max-width: $mobile-width) {
    flex-direction: column;
    margin-top: 54px;
  }

  .content {
    margin-left: 90px;
    max-width: 530px;

    @media (max-width: $mobile-width) {
      margin-left: 0px;
      margin-top: 24px;
    }

    .title {
      margin-bottom: 25px;
      line-height: 36px;
      font-weight: bold;
      font-size: 24px;
      color: #073327;

      @media (max-width: $mobile-width) {
        text-align: center;
      }
    }

    .input-field {
      width: 380px;

      @media (max-width: $mobile-width) {
        width: 100%;
      }
    }

    .checkbox-wrapper {
      margin-top: 16px;

      label {
        align-items: flex-start;

        input {
          margin-top: 3px;
        }

        .text {
          line-height: 20px;
          font-size: 12px;
          font-weight: 500;
        }
      }
    }

    .general-error {
      margin-top: 8px;
      line-height: 20px;
      color: #F74848;
      font-weight: 600;
      font-size: 12px;
    }

    .kredy-button {
      margin-top: 48px;

      @media (max-width: $mobile-width) {
        width: 100%;
      }
    }
  }
}
