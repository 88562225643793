@import 'vars.scss';

.main-background {
  position: absolute;
  top: 0px;
  left: 346px;
  right: 0px;
  height: 0px;
  z-index: -1;

  @media (max-width: $mobile-width) {
    left: -20px;
    right: -20px;

    img {
      width: 100%;
    }
  }
}

.large-screen-backup-background {
  display: none;
  
  @media (min-width: 1920px) {
    display: block;
    position: absolute;
    top: 0px;
    left: 1574px;
    height: 724px;
    width: 2000px;
    background: #ebfef0;
  }
}
