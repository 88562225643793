.popup-overlay .popup.reject-offer-feedback-popup {
  padding: 36px 50px;

  @media (max-width: 616px) {
    padding: 25px 22px;
  }

  .description {
    margin-bottom: 16px;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
  }

  .options {
    li {
      display: flex;
      align-items: flex-start;
      margin-bottom: 16px;

      input {
        margin-top: 3px;
      }

      label {
        margin-left: 13px;
        font-weight: 500;
        font-size: 14px;
      }
    }
  }

  textarea {
    box-sizing: border-box;
    margin-left: 24px;
    padding: 4px 8px;
    width: 438px;
    height: 100px;
    border: 1px solid #AAAAAA;
    border-radius: 6px;
    outline: none;

    @media (max-width: 616px) {
      margin-left: 0px;
      width: 100%;
    }

    [disabled] {
      background-color: transparent;
    }
  }

  .kredy-button {
    margin: 30px auto 0 auto;
    width: 185px;

    @media (max-width: 616px) {
      margin-top: 16px;
      width: 100%;
    }
  }
}
