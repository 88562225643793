@import 'vars.scss';

.about-us-page {
  .main-cover-image {
    display: flex;
    width: 100%;
    height: 480px;
    overflow: hidden;

    @media (max-width: $mobile-width) {
      width: calc(100% + 40px);
      height: 40vw;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .main-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .about-us-wrapper {
      max-width: 724px;

      .title {
        margin-top: 40px;
        font-weight: bold;
        font-size: 36px;
        line-height: 44px;

        @media (max-width: $mobile-width) {
          margin-top: 16px;
          font-size: 24px;
          line-height: 36px;
        }
      }

      .date {
        margin-top: 16px;

        @media (max-width: $mobile-width) {
          margin-top: 8px;
        }
      }

      .content {
        margin-top: 24px;

        p {
          font-weight: 500;
          font-size: 14px;
          line-height: 24px;
          color: #444444;
        }

        h2 {
          margin: 32px 0 26px 0;
          font-weight: bold;
          font-size: 30px;
          line-height: 37px;

          @media (max-width: $mobile-width) {
            font-size: 18px;
            line-height: 26px;
          }
        }

        h3 {
          font-weight: bold;
          font-size: 24px;
          line-height: 36px;

          @media (max-width: $mobile-width) {
            font-size: 16px;
          }
        }

        h4 {
          font-weight: bold;
          font-size: 20px;
          line-height: 36px;

          @media (max-width: $mobile-width) {
            font-size: 14px;
          }
        }
      }
    }
  }
}
