.social-button {
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
  height: 45px;
  background: white;
  border: 1px solid #DDDDDD;
  border-radius: 6px;
  text-align: left;
  cursor: pointer;

  img {
    margin: 0 12px;
  }

  span {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
    padding-right: 22px;
    height: 100%;
    text-align: center;
    border-left: 1px solid #DDDDDD;
    font-weight: 500;
    font-size: 14px;
    color: #333333;
  }

  #g-signin2 {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0.001;

    > div {
      width: 100% !important;
      height: 100% !important;
    }
  }
}
