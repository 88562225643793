.user-link-list {
  li {
    &.disabled {
      opacity: 0.5;
    }

    &.log-out {
      cursor: pointer;
    }

    a {
      display: flex;
      align-items: center;
      width: 100%;
      height: 100%;
      color: #444444;

      &:hover {
        color: #72BF44;
      }
    }
  }
}
