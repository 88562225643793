.title {
  margin-bottom: 8px;
  font-weight: bold;
  font-size: 18px;
}

.list {
  li {
    padding: 8px 0;
    border-bottom: 1px solid #E7E7E7;

    a {
      display: inline-block;
      color: #444444;

      .notificationTitle {
        display: flex;
        align-items: center;
        margin-bottom: 8px;
        font-weight: 600;
        font-size: 14px;

        .unseenIndicator {
          margin-right: 8px;
          width: 8px;
          height: 8px;
          border-radius: 8px;
          background: #8CC540;
        }
      }

      .description {
        margin-bottom: 8px;
        font-weight: 500;
        font-size: 12px;
        color: #777777;
        line-height: 22px;

        a {
          color: #76BF44;

          &:hover {
            text-decoration: underline;
          }
        }
      }

      .date {
        font-weight: 500;
        font-size: 10px;
        color: #76BF44;
      }
    }
  }
}
