.register-popup {
  .input-field-wrapper {
    margin-top: 20px;
  }

  .details {
    margin-top: 12px;
    line-height: 20px;
    font-weight: 500;
    font-size: 12px;
  }

  .kredy-button {
    margin-top: 24px;
    width: 100%;
  }

  .input-field-wrapper {
    .error-message {
      line-height: 20px;
      font-weight: 600;
      font-size: 12px;
    }
  }

  .checkbox-wrapper {
    margin-top: 16px;

    label {
      align-items: flex-start;

      input {
        margin-top: 3px;
      }

      .text {
        line-height: 20px;
        font-size: 12px;
        font-weight: 500;
      }
    }
  }

  .social-button {
    margin-top: 24px;
  }

  .already-registered {
    margin-top: 24px;
    padding-top: 24px;
    border-top: 1px solid #DDDDDD;
  }
}
