@import 'vars.scss';

.step-input-wrapper {
  &.error {
    .input {
      input {
        border-color: $error-color;

        &:focus {
          border-color: $error-color;
        }
      }
    }
  }

  &.success {
    .input {
      input {
        border-color: $success-color;
      }
    }
  }

  .input {
    position: relative;

    input {
      box-sizing: border-box;
      padding: 0 16px;
      width: 100%;
      height: 45px;
      font-weight: 500;
      font-size: 14px;
      color: #444444;
      background: white;
      border: 1px solid #AAAAAA;
      border-radius: 6px;
      outline: none;

      &:focus {
        border-color: $success-color;
      }

      /* Chrome, Safari, Edge, Opera */
      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      /* Firefox */
      &[type=number] {
        -moz-appearance: textfield;
      }
    }
  }

  .error-message {
    margin-top: 6px;
    font-size: 14px;
    color: $error-color;
  }
}
