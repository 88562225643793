.currency-toggle {
  display: flex;
  align-items: flex-start;
  margin-top: 6px;

  .text {
    font-weight: 500;
    font-size: 14px;
    color: #777777;

    &.active {
      font-weight: 600;
      color: #16B14B;
    }
  }

  .MuiSwitch-root {
    margin-top: -9px;
  }
}
