.user-menu-wrapper {
  position: relative;

  .user-menu {
    display: flex;
    align-items: center;
    user-select: none;
    cursor: pointer;

    &.open {
      .name {
        color: #72BF44;
      }

      .chevron {
        transform: rotate(180deg);
      }
    }

    .name {
      margin: 0 10px 0 12px;
      font-size: 14px;
    }
  }

  .user-link-list {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 54px;
    right: 0px;
    box-sizing: border-box;
    padding: 10px 0;
    min-width: 250px;
    background: #FFFFFF;
    box-shadow: 0px 4px 30px rgba(27, 143, 39, 0.15);
    border-radius: 6px;

    li {
      display: flex;
      align-items: center;
      padding: 0 20px;
      height: 36px;
      line-height: 36px;
      font-weight: 500;
      font-size: 14px;

      &.active a {
        color: #72BF44;
        font-weight: bold;
      }

      &.log-out:hover {
        color: #72BF44;
      }

      .icon {
        margin-right: 10px;
      }
    }
  }
}
