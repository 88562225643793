.step-field-label {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
  font-size: 14px;
  color: #333333;

  @media (max-width: 1024px) {
    font-size: 12px;
  }

  .mandatory-indicator {
    margin-left: 2px;
    color: #76BF44;
  }
}
