.popup-overlay {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 20px 0;
  overflow: auto;
  z-index: 999;
  background: hsla(0, 0%, 0%, 0.5);

  .popup {
    position: relative;
    box-sizing: border-box;
    margin: auto 0;
    padding: 24px 100px 39px 100px;
    width: 600px;
    background: rgba(255, 255, 255, 0.9);
    box-shadow: 0px 4px 30px rgba(27, 143, 39, 0.15);
    border-radius: 6px;
    backdrop-filter: blur(4px);

    .close {
      position: absolute;
      top: 10px;
      right: 10px;
    }

    h2 {
      margin-bottom: 36px;
      font-weight: bold;
      font-size: 18px;
      text-align: center;
      color: #073327;
    }
  }

  @media (max-width: 616px) {
    align-items: flex-start;

    .popup {
      padding: 24px 20px 39px 20px;
      width: 100%;
      min-height: calc(100vh - 54px);
      border-bottom-right-radius: 0px;
      border-bottom-left-radius: 0px;
      animation: popup-slidein 0.4s forwards;

      @keyframes popup-slidein {
        from {
          top: 100%;
        }

        to {
          top: 54px;
        }
      }
    }
  }
}
