@import 'vars.scss';

$slider-box-mobile-width: 600px;

.slider-box {
  position: relative;
  z-index: 1;
  margin-top: 81px;
  width: 724px;
  background: rgba(255, 255, 255, 0.9);
  box-shadow: 0px 4px 30px rgba(27, 143, 39, 0.15);
  backdrop-filter: blur(10px);
  border-radius: 6px;
  overflow: hidden;

  &.loading {
    pointer-events: none;

    .kredy-button {
      opacity: 0.5;
    }
  }

  &.no-data {
    pointer-events: none;

    .input-bound-slider,
    .currency-toggle {
      opacity: 0;
    }

    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: rgba(255, 255, 255, 0.5);
    }
  }

  &.data-loaded-animation {
    .input-bound-slider,
    .currency-toggle {
      animation: appear 0.5s forwards;

      @keyframes appear {
        from { opacity: 0; }
        to   { opacity: 1; }
      }
    }
  }

  .tabs {
    display: flex;

    button {
      flex: 1;
      height: 49px;
      line-height: 49px;
      text-align: center;
      color: #073327;
      font-weight: bold;
      font-size: 16px;
      background-color: #c9dbc3;
      cursor: pointer;

      &:not(.disabled) {
        &.active {
          background-color: transparent;
          color: #72BF44;
          cursor: default;
        }

        &:hover {
          color: #72BF44;
        }
      }

      &.disabled {
        opacity: 0.5;
        cursor: default;
      }

      &:first-child {
        border-bottom-right-radius: 6px;

        &.active {
          border-bottom-right-radius: 0px;
        }
      }

      &:last-child {
        position: relative;
        border-bottom-left-radius: 6px;

        &::before {
          content: '';
          position: absolute;
          top: 0px;
          left: -6px;
          width: 6px;
          height: 6px;
          background-color: #c9dbc3;
        }

        &::after {
          content: '';
          position: absolute;
          top: 0px;
          left: -6px;
          width: 6px;
          height: 6px;
          background-color: white;
          border-top-right-radius: 6px;
        }

        &.active {
          &::before {
            left: 0px;
          }

          &::after {
            left: 0px;
            border-top-right-radius: 0px;
            border-top-left-radius: 6px;
          }
        }
      }
    }

    @media (max-width: $slider-box-mobile-width) {
      button {
        font-size: 14px;
      }
    }
  }

  @media (max-width: $mobile-width) {
    margin-top: -4px;
    width: 100%;
  }
}
