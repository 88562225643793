@import 'vars.scss';

.accordion {
  display: flex;
  justify-content: center;

  .wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;

    .section {
      flex-basis: auto;
      padding-left: 20px;
      border-top: 1px solid #DCDCDC;

      &:last-child {
        border-bottom: 1px solid #DCDCDC;
      }

      &.toggleable {
        position: relative;
        overflow: hidden;
        transition: all 0.3s;

        &.expanded {
          flex-basis: auto;

          .icon {
            svg {
              stroke: #72BF44;
            }
          }

          .icon.plus {
            display: none;
          }

          .title {
            color: #72BF44;
          }

          .content {
            display: block;
          }
        }

        &:not(.expanded) {
          &:hover {
            .title {
              color: #72BF44;
            }

            .icon {
              svg {
                stroke: #72BF44;
              }
            }

            .icon.minus {
              display: none;
            }
          }
        }

        .icon {
          display: flex;
          position: absolute;
          top: 23px;
          right: 20px;

          svg {
            stroke: #073327;
          }
        }
      }

      .title {
        position: relative;
        z-index: 1;
        margin: 0px;
        padding: 22px 50px 20px 0px;
        font-weight: bold;
        font-size: 18px;
        line-height: 26px;
        color: #073327;
        cursor: pointer;

        @media (max-width: $mobile-width) {
          font-size: 16px;
          line-height: 22px;
        }
      }

      .content {
        display: none;
        margin-bottom: 18px;
        padding-right: 50px;
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
        color: #444444;

        @media (max-width: $mobile-width) {
          padding-right: 0px;
        }
      }
    }
  }
}
