@import 'vars.scss';

.error-404-page {
  h2 {
    margin-top: 96px;
    margin-bottom: 36px;
    text-align: center;
    font-weight: bold;
    font-size: 36px;

    @media (max-width: $mobile-width) {
      margin-top: 14px;
    }
  }

  .description {
    text-align: center;
    font-weight: bold;
    font-size: 18px;
    line-height: 26px;

    @media (max-width: 918px) {
      font-size: 14px;

      p {
        margin-top: 10px;
        line-height: 20px;
      }
    }

    img {
      margin-top: 42px;

      @media (max-width: 918px) {
        width: 100%;
      }
    }

    .kredy-button {
      margin: 40px auto 0 auto;
      width: 256px;

      @media (max-width: 918px) {
        width: 100%;
      }
    }
  }
}
