@import "vars.scss";

.side-menu-page {
  .page-wrapper {
    .main {
      .request-list {
        margin-top: 55px;

        @media (max-width: $mobile-width) {
          margin-top: 26px;
        }

        .tabs {
          display: flex;
          border-bottom: 1px solid #CFF09E;

          @media (max-width: $mobile-width) {
            justify-content: center;
            margin: 0 -20px;
          }

          .tab {
            position: relative;
            padding: 8px 20px;
            font-weight: 600;
            font-size: 18px;
            cursor: pointer;

            @media (max-width: $mobile-width) {
              font-size: 16px;
            }

            &.active {
              color: #76BF44;

              &::after {
                content: ' ';
                position: absolute;
                left: 0px;
                right: 0px;
                bottom: 0px;
                height: 4px;
                background: #72BF44;
                border-radius: 3px 3px 0px 0px;
              }
            }
          }
        }

        .list {
          width: 100%;
          margin-top: 39px;
          box-shadow: 0px 8px 30px rgba(27, 143, 39, 0.15);
          border-radius: 6px;
          padding: 6px 30px;

          thead {
            tr {
              height: 68px;

              th {
                font-weight: bold;
                font-size: 12px;
                line-height: 22px;
                text-align: left;
                text-transform: uppercase;
                color: #073327;

                &:first-child {
                  padding-left: 6px;
                }

                &:last-child {
                  padding-right: 30px;
                }
              }
            }
          }

          tbody {
            tr {
              height: 68px;

              td {
                font-weight: 600;
                font-size: 14px;
                color: #444444;
                border-bottom: 1px solid #E7E7E7;

                &:first-child {
                  padding-left: 6px;
                }

                &:last-child {
                  padding-right: 30px;
                }
              }

              &:last-child {
                td {
                  border-bottom-width: 0px;
                }
              }
            }
          }
        }

        .mobile-list {
          padding-top: 32px;

          .request-box {
            margin-bottom: 24px;
            padding: 20px;
            background: rgba(255, 255, 255, 0.9);
            box-shadow: 0px 4px 30px rgba(27, 143, 39, 0.15);
            backdrop-filter: blur(10px);
            border-radius: 6px;

            table {
              width: 100%;

              td {
                padding: 0 2px 24px 2px;
                font-weight: 600;
                font-size: 14px;
              }

              .heading-cells {
                td {
                  padding: 0 2px 12px 2px;
                  font-style: normal;
                  font-weight: bold;
                  font-size: 12px;
                  text-transform: uppercase;

                  &:first-child {
                    width: 30%;
                  }

                  &:nth-child(3) {
                    width: 40%;
                  }
                }
              }
            }

            .offer-text {
              padding-bottom: 12px;
              font-style: normal;
              font-weight: bold;
              font-size: 12px;
              text-transform: uppercase;
            }
          }
        }

        .offer-list {
          display: flex;
          line-height: 0;

          li {
            margin-right: 10px;

            .provider {
              position: relative;
              cursor: pointer;

              &:hover {
                box-shadow: 0px 2px 10px rgba(27, 143, 39, 0.25);
                border-radius: 6px;
              }

              .no-provider {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 24px;
                height: 24px;
                background-color: black;
                border-radius: 100%;
                color: white;
              }

              .status-icon {
                position: absolute;
                top: -4px;
                right: -6px;
              }
            }
          }
        }

        .link {
          text-decoration-line: underline;
          color: #76BF44;
          font-weight: 600;
          font-size: 14px;
          cursor: pointer;
        }
      }
    }
  }
}
