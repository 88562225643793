.email-sent-popup {
  display: flex;
  flex-direction: column;
  align-items: center;

  .image {
    width: 225px;
  }

  .details {
    margin-top: 35px;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
  }

  .kredy-button {
    margin-top: 35px;
  }
}
