@import 'vars.scss';
@import 'zIndex.scss';

.fake-top-bar {
  display: flex;
  width: 100%;
  height: $top-bar-height;

  @media (max-width: $mobile-width) {
    height: $top-bar-mobile-height;
  }
}

.top-bar {
  display: flex;
  justify-content: center;
  position: relative;
  z-index: $z-index-top-bar;
  width: 100%;

  &.sticky {
    position: fixed;
    top: -$top-bar-height;
    left: 0;
    background: rgba(255, 255, 255, 0.9);
    box-shadow: 0px 1px 35px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(10px);
    animation: slidein 0.3s forwards;

    @keyframes slidein {
      from {
        top: -$top-bar-height;
      }

      to {
        top: 0;
      }
    }
  }

  .wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    width: 1220px;
    height: $top-bar-height;

    .menu {
      display: flex;
      align-items: center;

      .notification-icon-wrapper {
        margin-right: 24px;
      }

      ul.menu-link-list {
        li {
          display: inline-block;
          margin-right: 45px;

          a {
            color: #073135;
            font-weight: 500;
            font-size: 14px;
          }
        }
      }

      .transparent-button {
        margin-left: 10px;
      }
    }

    .mobile-notification-icon-wrapper,
    .mobile-user-menu-icon,
    .mobile-menu-icon,
    .mobile-profile-icon {
      display: none;
    }
  }

  @media (max-width: $mobile-width) {
    width: 100%;

    &.sticky {
      .wrapper {
        width: calc(100% - 40px);
      }
    }

    .wrapper {
      height: $top-bar-mobile-height;

      .menu {
        display: none;
      }

      .mobile-notification-icon-wrapper {
        display: block;
        position: absolute;
        top: 4px;
        right: 92px;
      }

      .mobile-user-menu-icon {
        display: flex;
        position: absolute;
        top: 4px;
        right: 35px;
      }

      .mobile-menu-icon {
        display: flex;
        position: absolute;
        top: 4px;
        right: -15px;
      }

      .mobile-profile-icon {
        display: flex;
        position: absolute;
        top: 4px;
        right: 35px;
      }
    }
  }
}
