@import 'vars.scss';

.news-list-page {
  .news-list-wrapper {
    margin-top: 96px;

    @media (max-width: $mobile-width) {
      margin-top: 14px;
    }

    .news-list {
      margin-top: 32px;

      @media (max-width: $mobile-width) {
        margin-top: 24px;
      }

      .list {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-column-gap: 16px;
        grid-row-gap: 49px;

        @media (max-width: $mobile-width) {
          grid-template-columns: repeat(4, 1fr);
          grid-row-gap: 29px;
        }

        @media (max-width: 740px) {
          grid-template-columns: repeat(2, 1fr);
        }

        @media (max-width: 399px) {
          grid-template-columns: repeat(1, 1fr);

          .news-summary .cover-image {
            height: 230px;
          }
        }
      }
    }
  }
}
