.kredy-button {
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  padding: 1px;
  background: linear-gradient(90deg, #16B14B 0.01%, #55B848 24.47%, #76BF44 53.41%, #86C341 79.49%, #8CC540 100.04%);
  border: none;
  color: #073327;
  font-weight: 600;
  border-radius: 6px;
  outline: none;
  cursor: pointer;

  &[disabled] {
    pointer-events: none;
    opacity: 0.5;
  }

  &.visually-disabled {
    opacity: 0.5;
    cursor: default;
  }

  .label {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex: 1;
    padding: 14px 40px;
    width: 100%;
    background: white;
    border-radius: 6px;
  }

  .left-icon {
    position: absolute;
    left: 18px;
  }

  .right-icon {
    position: absolute;
    right: 18px;
  }

  &:not(.visually-disabled) {
    &:hover {
      color: white;

      .label {
        background: transparent;
      }
    }

    &:active {
      color: white;
      background: linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), linear-gradient(90deg, #8CC540 0.01%, #86C341 27.1%, #76BF44 53.41%, #55B848 82.32%, #16B14B 100.04%);

      .label {
        background: transparent;
      }
    }
  }

  &.primary {
    color: white;

    .label {
      background: transparent;
    }

    &:not(.visually-disabled) {
      &:hover,
      &.active {
        color: white;
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), linear-gradient(90deg, #8CC540 0.01%, #86C341 27.1%, #76BF44 53.41%, #55B848 82.32%, #16B14B 100.04%);

        .label {
          background: transparent;
        }
      }
    }
  }

  &.login {
    background: transparent;
    border: 1px solid #2BD995;

    .label {
      background: transparent;
    }

    &:not(.visually-disabled) {
      &:hover {
        background: #2BD995;

        .label {
          background: transparent;
        }
      }

      &:active {
        background: #24CA89;

        .label {
          background: transparent;
        }
      }
    }
  }

  &.red {
    background: #F44336;

    .label {
      background: #F44336;
      color: white;
    }
  }
}
