@import 'vars.scss';

.contacts-page {
  display: flex;
  justify-content: center;

  .main-wrapper {
    display: flex;
    justify-content: center;

    .contacts-page-wrapper {
      margin-top: 96px;
      width: 620px;
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      color: #444444;

      @media (max-width: $mobile-width) {
        width: 100%;
      }

      @media (max-width: $mobile-width) {
        margin-top: 14px;
      }

      h1 {
        margin-bottom: 48px;
        font-size: 36px;
        line-height: 44px;
        font-weight: bold;
        color: #222222;
        text-align: center;
      }

      .contact-list {
        display: flex;
        justify-content: space-around;
        align-items: center;
        padding: 0px 80px;

        @media (max-width: $mobile-width) {
          flex-direction: column;
          align-items: flex-start;
          margin: 0 auto;
          padding: 0px;
          width: 180px;
        }

        li {
          display: flex;
          justify-content: center;
          align-items: center;

          @media (max-width: $mobile-width) {
            margin-bottom: 32px;
          }

          > img {
            margin-right: 16px;
          }
        }
      }

      h2 {
        margin-top: 88px;
        font-weight: bold;
        font-size: 30px;
        line-height: 37px;
        text-align: center;
        color: #222222;

        @media (max-width: $mobile-width) {
          margin-top: 48px;
          font-size: 18px;
          line-height: 26px;
        }
      }

      .contact-form {
        margin-top: 54px;
        padding: 40px;
        background: #FFFFFF;
        box-shadow: 0px 4px 30px rgba(27, 143, 39, 0.15);
        border-radius: 6px;

        @media (max-width: $mobile-width) {
          margin-top: 32px;
        }

        .contacts-page-field {
          margin-top: 28px;

          &:first-child {
            margin-top: 0px;
          }

          .checkbox-wrapper {
            font-size: 12px;
            line-height: 18px;
            color: #222222;

            label {
              align-items: flex-start;

              > input {
                margin-top: 3px;
              }
            }
          }
        }

        .kredy-button {
          margin-top: 24px;

          @media (max-width: $mobile-width) {
            width: 100%;
          }
        }

        .general-error {
          margin-top: 8px;
          line-height: 20px;
          color: #F74848;
          font-weight: 600;
          font-size: 12px;
        }
      }
    }
  }
}
