.mobile-user-menu-content {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: auto;
  z-index: 999;
  background: white;

  .header {
    position: relative;
    padding: 16px 20px;
    border-bottom: 1px solid #DCDCDC;

    .close {
      position: absolute;
      top: 4px;
      right: 4px;
    }
  }

  .menu-links {
    padding: 10px 20px;

    ul.user-link-list {
      li {
        display: flex;
        align-items: center;
        height: 36px;
        line-height: 36px;
        font-weight: 500;
        font-size: 14px;

        &.active a {
          color: #72BF44;
          font-weight: bold;
        }

        &.log-out:hover {
          color: #72BF44;
        }

        .icon {
          margin-right: 10px;
        }
      }
    }
  }
}
