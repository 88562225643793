@import "vars.scss";

.wrapper {
  position: relative;
  display: flex;
  margin-bottom: 43px;

  @media (max-width: $mobile-width) {
    margin-bottom: 0;
  }

  @media (max-width: $mobile-width) {
    flex-direction: column;
  }

  .notifType {
    width: 250px;

    .list {
      li {
        margin-bottom: 16px;
      }

      @media (max-width: $mobile-width) {
        margin-bottom: 37px;
      }
    }

    .title {
      display: flex;
      align-items: center;
      margin-bottom: 27px;
      font-weight: bold;
      font-size: 18px;

      @media (max-width: $mobile-width) {
        font-size: 16px;
      }

      img {
        margin-right: 10px;
      }
    }
  }
}
