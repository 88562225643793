@import 'vars.scss';

.App {
  display: flex;
  flex-direction: column;
  align-items: center;

  background:
    url(../../assets/img/bg1.png) no-repeat left 1314px,
    url(../../assets/img/bg2.png) no-repeat right 2096px,
    url(../../assets/img/bg3.png) no-repeat left 3168px,
    url(../../assets/img/bg4.png) no-repeat right 4367px;

  .main-wrapper {
    position: relative;
    margin-top: -$top-bar-height;
    padding-top: $top-bar-height;
    width: 1220px;
  }

  @media (max-width: $mobile-width) {
    padding: 0 20px;
    background: none;

    .main-wrapper {
      box-sizing: border-box;
      margin-top: -$top-bar-mobile-height;
      padding-top: $top-bar-mobile-height;
      width: 100%;
    }
  }
}
