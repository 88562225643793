@import "vars.scss";

.side-menu-page {
  .page-wrapper {
    .main {
      h1 {
        margin-bottom: 54px;
      }

      .profile-page {
        .profile-page-field {
          display: flex;

          @media (max-width: $mobile-width) {
            flex-direction: column;
            align-items: center;
            margin-bottom: 20px;
          }

          &.disabled {
            .input-field-wrapper {
              .input-field .field .inner-field input {
                color: #AAAAAA;
              }
            }
          }

          .input-field-wrapper {
            flex-basis: 475px;
            margin-bottom: 24px;

            @media (max-width: $mobile-width) {
              flex-basis: auto;
              margin-bottom: 0px;
              width: 100%;
            }
          }
        }

        .title {
          margin: 34px 0 32px 0;
          font-weight: bold;
          font-size: 30px;

          @media (max-width: $mobile-width) {
            font-size: 18px;
          }

          &.notification-title {
            margin-top: 54px;
          }
        }

        @media (max-width: $mobile-width) {
          button[type=submit] {
            width: 100%;
          }
        }
      }
    }
  }
}
