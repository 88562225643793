.forgotten-password-popup {
  .input-field-wrapper {
    margin-top: 20px;

    .error-message {
      line-height: 20px;
      font-weight: 600;
      font-size: 12px;
    }
  }

  .kredy-button {
    margin-top: 24px;
    width: 100%;
  }
}
