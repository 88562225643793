@import 'vars.scss';

.faq-section {
  margin-top: 130px;

  @media (max-width: $mobile-width) {
    margin-top: 54px;
  }

  .faq-section-wrapper {
    position: relative;

    @media (max-width: $mobile-width) {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }

    .accordion {
      width: 600px;

      @media (max-width: $mobile-width) {
        width: 100%;
      }
    }

    .background {
      position: absolute;
      top: -30px;
      right: 0px;

      @media (max-width: $mobile-width) {
        position: static;
        margin-bottom: 16px;
        max-width: 100%;
      }
    }
  }

  .kredy-button {
    margin-top: 40px;
    width: 300px;

    @media (max-width: $mobile-width) {
      width: 100%;
      text-align: center;
    }
  }
}
