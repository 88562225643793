@import 'vars.scss';

.secondary-background {
  position: absolute;
  top: 140px;
  left: 506px;
  line-height: 0;
  z-index: -1;

  @media (max-width: $mobile-width) {
    position: static;
    width: 100%;

    img {
      width: 100%;
    }
  }
}
