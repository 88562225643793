.notificationDropdown {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 66px;
  right: 0px;
  box-sizing: border-box;
  padding: 20px 27px;
  width: 405px;
  max-height: calc(80vh - 90px);
  overflow: auto;
  background: #FFFFFF;
  box-shadow: 0px 4px 30px rgba(27, 143, 39, 0.15);
  border-radius: 6px;
  cursor: default;
}
