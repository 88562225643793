.checkbox-wrapper {
  label {
    display: flex;
    align-items: center;

    input[type="checkbox"] {
      min-width: 20px;
    }

    .text {
      display: flex;
      align-items: center;
      margin-left: 12px;
      font-size: 14px;
      color: #333333;
    }

    a {
      color: #72BF44;
    }
  }

  &.error {
    label {
      .text {
        color: #F44336;
      }
    }
  }
}
