@import 'vars.scss';

.how-it-works-section {
  margin-top: 130px;

  .steps {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-top: 50px;

    .step {
      width: 175px;

      .number {
        margin-bottom: 7px;
        font-weight: 600;
        font-size: 18px;
        color: #2BD995;
      }

      .title {
        margin-bottom: 22px;
        height: 40px;
        font-weight: bold;
        font-size: 16px;
        line-height: 20px;
        color: #073327;
      }
    }

    .arrow {
      margin: 31px 0 0 20px;
    }
  }

  @media (max-width: $mobile-width) {
    margin-top: 54px;

    .steps {
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .step {
        width: auto;
        text-align: center;

        .title {
          height: auto;
        }
      }

      .arrow {
        transform: rotate(90deg);
        margin: 10px 0 20px 0;
      }
    }
  }
}
