@import 'vars.scss';

html, body, #root {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  color: #444444;
}

html, body, button, textarea {
  font-family: 'Montserrat', sans-serif;
}

ul, li, h1, h2, p {
  margin: 0;
  padding: 0;
}

ul {
  list-style-type: none;
}

[data-richtext] {
  ul {
    margin: 0;
    padding: 0 40px;
    list-style-type: disc;
  }

  p {
    margin: 16px 0;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  table {
    border-collapse: collapse;

    td {
      padding: 2px 4px;
      border: 1px solid black;
    }
  }

  strong {
    margin-left: 0px !important;
  }

  a {
    color: #72BF44;
  }
}

input {
  margin: 0;
  padding: 0;
  border-radius: 0px;
  font-family: 'Montserrat', sans-serif;
}

button {
  margin: 0;
  padding: 0;
  border: none;
  outline: none;
  background: transparent;
}

[role=button] {
  outline: none;
}

a {
  text-decoration: none;
  outline: none;
}

a.link {
  text-decoration: underline;
  color: #444444;

  &:hover,
  &:active {
    text-decoration: underline;
  }

  &:visited {
    color: #555555;
  }
}
