@import 'vars.scss';

.bottom-bar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 1220px;
  height: 78px;

  .logo {
    flex: 1.5;
  }

  .social-icons {
    flex: 1;
    display: flex;
  }

  .copyright {
    flex: 1;
  }

  @media (max-width: $mobile-width) {
    flex-direction: column;
    padding: 30px 0;
    height: auto;

    .logo {
      margin-bottom: 30px;
    }

    .social-icons {
      margin-bottom: 20px;
    }
  }
}
