@import 'vars.scss';

.home-page {
  .welcome-section {
    margin-top: 90px;

    h1 {
      margin-bottom: 34px;
      font-weight: bold;
      font-size: 36px;
      color: #222222;
    }

    .intro {
      margin-bottom: 24px;
      width: 450px;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
    }

    @media (max-width: $mobile-width) {
      margin-top: 14px;

      h1 {
        margin-bottom: 8px;
      }

      .intro {
        width: 244px;
      }

      .register-button {
        display: none;
      }

      .transparent-button {
        display: none;
      }
    }
  }
}
