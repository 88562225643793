@import 'vars.scss';

.step-wrapper {
  &.step-zero {
    background: url(../../../assets/img/step0.svg) no-repeat right 0px center;

    @media (max-width: 1024px) {
      background: none;
    }
  }

  h1 {
    margin-bottom: 16px;
    font-size: 36px;

    @media (max-width: $mobile-width) {
      font-size: 24px;
    }
  }

  .details {
    font-weight: 500;
    font-size: 14px;

    .special-symbol {
      color: #76BF44;
    }
  }

  .step-content {
    display: flex;
    align-items: flex-start;
    margin-top: 40px;

    @media (max-width: 1024px) {
      margin-top: 20px;
    }

    .fields {
      width: 540px;

      .step-input-wrapper,
      .select-wrapper,
      .search-field-wrapper,
      .checkbox-wrapper {
        margin-bottom: 24px;

        @media (max-width: 1024px) {
          margin-bottom: 20px;
        }
      }

      .slider-box {
        .slider-box-tab-content .button-wrapper {
          display: none;
        }
      }
    }

    .summary {
      flex: 1;
      box-sizing: border-box;
      margin-left: 80px;
      padding: 28px 36px;
      background: rgba(255, 255, 255, 0.9);
      box-shadow: 0px 4px 30px rgba(27, 143, 39, 0.15);
      backdrop-filter: blur(10px);
      border-radius: 6px;

      &.collapsible {
        .title {
          cursor: pointer;
        }

        &.collapsed {
          .step-name,
          .attribute {
            display: none;
          }
        }
      }

      .title {
        color: #073327;
        font-weight: bold;
        font-size: 20px;
      }
    }

    @media (max-width: 1024px) {
      flex-direction: column;

      .fields {
        width: 100%;
      }

      .summary {
        margin-top: 32px;
        margin-left: 0px;
        padding: 22px 25px;
        width: 100%;
      }
    }
  }

  .continue-button {
    margin-top: 40px;
    width: 422px;

    @media (max-width: 1024px) {
      width: 100%;
    }
  }
}
