@import 'vars.scss';
@import 'zIndex.scss';

.step-indicator {
  display: flex;
  margin: 66px 80px 54px 80px;

  @media (max-width: $mobile-width) {
    margin: 38px 0 26px 0;
  }

  .step-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;
    min-width: 146px;
    pointer-events: none;

    @media (max-width: $mobile-width) {
      min-width: 50px;
    }

    &.active {
      .circle-wrapper {
        .circle {
          border-color: #76BF44;
          color: #444444;
        }

        .connecting-line {
          background-color: #76BF44;
        }
      }

      .name {
        color: #444444;
      }
    }

    &.done {
      pointer-events: all;
      cursor: pointer;

      .circle-wrapper {
        .circle {
          background: linear-gradient(90deg, #16B14B 0.01%, #55B848 24.47%, #76BF44 53.41%, #86C341 79.49%, #8CC540 100.04%);
          border-width: 0px;

          .circle-text {
            display: none;
          }
        }

        .connecting-line {
          background-color: #76BF44;
        }
      }

      .name {
        color: #76BF44;
      }
    }

    &:first-child {
      .circle-wrapper {
        .connecting-line {
          width: 50%;
          left: 50%;
        }
      }
    }

    &:last-child {
      .circle-wrapper {
        .connecting-line {
          width: 50%;
          left: 0px;
        }
      }
    }

    .circle-wrapper {
      display: flex;
      justify-content: center;
      position: relative;
      width: 100%;

      .circle {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        z-index: $z-index-step-indicator-circle;
        box-sizing: border-box;
        width: 70px;
        height: 70px;
        background-color: white;
        border: 2px solid #BBBBBB;
        border-radius: 100%;
        font-weight: 500;
        font-size: 24px;
        color: #BBBBBB;

        @media (max-width: $mobile-width) {
          width: 30px;
          height: 30px;
          font-size: 14px;

          .checkmark-icon {
            width: 10px;
          }
        }
      }

      .connecting-line {
        position: absolute;
        z-index: $z-index-step-indicator-line;
        top: calc(50% - 1px);
        height: 2px;
        width: 100%;
        background-color: #BBBBBB;
      }
    }

    .name {
      margin-top: 5px;
      padding: 0 4px;
      text-align: center;
      font-weight: 500;
      font-size: 16px;
      color: #BBBBBB;

      @media (max-width: 768px) {
        font-size: 1.8vw;
      }
    }
  }
}
