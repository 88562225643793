@import 'vars.scss';

.forgotten-password-page {
  font-size: 12px;
  font-weight: 500;

  .forgotten-password-wrapper {
    margin-top: 96px;
    background: url(../../assets/img/password-creation-bg.svg) no-repeat right 77px top 105px;

    .box {
      width: 400px;

      h1 {
        margin-bottom: 53px;
        font-size: 36px;
        font-weight: bold;
      }

      .text {
        p {
          margin-bottom: 24px;

          &:first-child {
            font-weight: 600;
          }
        }
      }

      .input-field-wrapper {
        margin-top: 20px;
      }

      .kredy-button {
        margin-top: 35px;
        width: 100%;
      }
    }

    @media (max-width: $mobile-width) {
      margin-top: 14px;
    }

    @media (max-width: 1024px) {
      background: none;

      .box {
        width: 100%;
      }
    }
  }

  .page-footer {
    margin-top: 100px;
  }
}
