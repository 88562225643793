@import 'vars.scss';

.login-page {
  .login-wrapper {
    margin-top: 96px;
    background: url(../../assets/img/authentication-bg.svg) no-repeat right 77px top 105px;

    .box {
      width: 400px;

      h1 {
        margin-bottom: 53px;
        font-size: 36px;
        font-weight: bold;
      }

      .text {
        p {
          margin-bottom: 24px;

          &:first-child {
            font-weight: 600;
          }
        }
      }

      .social-button {
        margin-bottom: 24px;
      }

      .input-field-wrapper {
        margin-top: 20px;
      }

      .forgotten-password {
        display: inline-block;
        margin-top: 13px;
        font-size: 12px;
        font-weight: 500;
        text-decoration: underline;
        cursor: pointer;
      }

      .kredy-button {
        margin-top: 35px;
        width: 100%;
      }

      .new-user {
        margin-top: 24px;
        font-size: 12px;
        font-weight: 500;
      }
    }

    @media (max-width: $mobile-width) {
      margin-top: 14px;
    }

    @media (max-width: 1024px) {
      background: none;

      .box {
        width: 100%;
      }
    }
  }

  .page-footer {
    margin-top: 100px;
  }
}
