.request-summary {
  .step-name {
    display: flex;
    position: relative;
    margin-top: 24px;
    margin-bottom: 16px;
    color: #444444;
    font-weight: bold;
    font-size: 18px;

    @media (max-width: 1024px) {
      justify-content: space-between;
    }

    .edit-icon {
      margin-top: -2px;
      height: 24px;

      @media (max-width: 1024px) {
        margin-right: -10px;
      }
    }
  }

  .attribute {
    margin-bottom: 8px;
    font-size: 14px;

    .label {
      margin-right: 10px;
    }

    strong {
      display: inline-block;
      font-weight: 600;
    }
  }
}
