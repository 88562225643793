@import 'vars.scss';

.input-field-wrapper {
  label {
    display: block;
    margin-bottom: 12px;
    font-size: 14px;
    color: #333333;

    .mandatory-indicator {
      color: #76BF44;
    }
  }

  &.error {
    .input-field,
    .input-field.active {
      .icon {
        background: $error-color;
      }

      .field {
        background: $error-color;
      }
    }
  }

  .input-field {
    display: flex;
    min-height: 46px;

    &.multiline {
      height: 140px;
    }

    &.active {
      .icon {
        background: #16B14B;
      }

      .field {
        background: linear-gradient(90deg, #16B14B 0.01%, #55B848 24.47%, #76BF44 53.41%, #86C341 79.49%, #8CC540 100.04%);
      }
    }

    &.no-icon {
      .icon {
        display: none;
      }

      .field {
        border-radius: 6px;

        .inner-field {
          left: 1px;
          border-radius: 6px;
        }
      }
    }

    .icon {
      position: relative;
      padding: 1px;
      flex-shrink: 0;
      flex-basis: 44px;
      height: 44px;
      border-radius: 6px 0px 0px 6px;
      background: linear-gradient(90deg, #16B14B 0.01%, #55B848 24.47%, #76BF44 53.41%, #86C341 79.49%, #8CC540 100.04%);

      .inner-icon {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 1px;
        bottom: 1px;
        left: 1px;
        right: 1px;
        background: white;
        border-radius: 6px 0px 0px 6px;
      }
    }

    .field {
      flex: 1;
      position: relative;
      border-radius: 0px 6px 6px 0px;
      background: #DDDDDD;

      .inner-field {
        position: absolute;
        top: 1px;
        bottom: 1px;
        left: 0px;
        right: 1px;
        border-radius: 0px 6px 6px 0px;
        overflow: hidden;

        input,
        textarea {
          box-sizing: border-box;
          position: absolute;
          top: 0px;
          left: 0px;
          padding: 0 16px;
          width: 100%;
          height: 100%;
          font-weight: 500;
          font-size: 14px;
          color: #444444;
          background: white;
          border: none;
          outline: none;
        }

        textarea {
          padding-top: 8px;
          padding-bottom: 8px;
          height: 138px;
          resize: none;
        }
      }
    }
  }

  .error-message {
    margin-top: 6px;
    font-size: 14px;
    color: $error-color;
  }
}
