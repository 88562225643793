@import 'vars.scss';

.embla {
  position: relative;
  max-width: 1220px;
  margin-left: auto;
  margin-right: auto;

  @media (max-width: $mobile-width) {
    max-width: 100vw;
  }
}

.embla__viewport {
  overflow: hidden;
  width: 100%;
}

.embla__viewport.is-draggable {
  cursor: move;
  cursor: grab;
}

.embla__viewport.is-dragging {
  cursor: grabbing;
}

.embla__container {
  display: flex;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -webkit-tap-highlight-color: transparent;
}

.embla__slide {
  position: relative;
  min-width: 290px;
  padding-left: 20px;

  &:first-child {
    padding-left: 0px;
  }

  @media (max-width: $mobile-width) {
    &:first-child {
      padding-left: 20px;
    }

    &:last-child {
      padding-right: 20px;
    }
  }
}

.embla__slide__inner {
  position: relative;
  overflow: hidden;
}

.embla__slide__img {
  position: absolute;
  display: block;
  top: 50%;
  left: 50%;
  width: auto;
  min-height: 100%;
  min-width: 100%;
  max-width: none;
  transform: translate(-50%, -50%);
}

.embla__button {
  outline: 0;
  cursor: pointer;
  background-color: transparent;
  touch-action: manipulation;
  position: absolute;
  z-index: 1;
  top: 50%;
  transform: translateY(-50%);
  border: 0;
  width: 30px;
  height: 30px;
  justify-content: center;
  align-items: center;
  fill: #1bcacd;
  padding: 0;
}

.embla__prev-next-buttons {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 60px;

  @media (max-width: $mobile-width) {
    margin-top: 16px;
  }
}

.embla__prev-next-buttons .chevron-left {
  margin-right: 16px;

  @media (max-width: $mobile-width) {
    margin-right: 80px;
  }
}
