.mobile-menu-content {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: auto;
  z-index: 999;
  background: white;

  .header {
    position: relative;
    padding: 16px 20px;
    border-bottom: 1px solid #DCDCDC;

    .close {
      position: absolute;
      top: 4px;
      right: 4px;
    }
  }

  .menu-links {
    padding: 10px 20px;
    border-bottom: 1px solid #DCDCDC;

    ul.menu-link-list {
      li {
        height: 36px;
        line-height: 36px;

        a {
          display: block;
          font-weight: 500;
          font-size: 14px;
          color: #444444;
        }
      }
    }
  }

  .form {
    margin: 24px 20px;
    width: calc(100% - 40px);
  }

  .input-field-wrapper {
    margin: 24px 0;
  }

  .register-button {
    margin: 24px 0px;
    width: 100%;
  }
}
