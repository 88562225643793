@import 'vars.scss';

.section-title {
  margin-bottom: 70px;

  h2 {
    font-size: 30px;
    font-weight: bold;
    color: #222222;
  }

  .title-separator {
    margin-top: 24px;
    width: 70px;
    height: 3px;
    background-color: #72BF44;
  }

  @media (max-width: $mobile-width) {
    margin-bottom: 20px;

    h2 {
      font-size: 24px;
      text-align: center;
    }

    .title-separator {
      margin: 24px auto 0 auto;
    }
  }
}
