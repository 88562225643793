.confirm-phone-popup {
  display: flex;
  flex-direction: column;
  align-items: center;

  .details {
    margin-top: 35px;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    text-align: center;

    p {
      margin-bottom: 6px;
    }
  }

  .buttons {
    display: flex;
    justify-content: space-around;
    margin-top: 120px;
    width: 100%;

    .kredy-button {
      width: 140px;
    }
  }
}
