@import 'vars.scss';
@import 'zIndex.scss';

.select-wrapper {
  position: relative;
  width: 100%;

  .value {
    display: flex;
    align-items: center;
    position: relative;
    box-sizing: border-box;
    padding-left: 16px;
    width: 100%;
    height: 45px;
    border: 1px solid #AAAAAA;
    border-radius: 6px;
    font-size: 14px;
    outline: none;
    cursor: pointer;

    .arrow-icon {
      position: absolute;
      top: 18px;
      right: 16px;
    }
  }

  .option-list {
    display: none;
  }

  &.open {
    .value {
      border-color: $success-color;
      border-bottom-left-radius: 0px;
      border-bottom-right-radius: 0px;

      .arrow-icon {
        transform: rotate(180deg);
      }
    }

    .option-list {
      display: block;
      position: absolute;
      top: 74px;
      left: 0px;
      right: 0px;
      z-index: $z-index-dropdown-menu;
      border: 1px solid $success-color;
      border-bottom-left-radius: 6px;
      border-bottom-right-radius: 6px;
      background-color: white;

      @media (max-width: 1024px) {
        top: 70px;
      }

      li {
        display: flex;
        align-items: center;
        padding: 0 16px;
        height: 45px;
        font-size: 14px;
        cursor: pointer;

        &.selected {
          color: $success-color;
        }

        &:hover {
          background-color: rgba(48, 244, 138, 0.1);
        }

        &.keyboard-hovered {
          background-color: rgba(48, 244, 138, 0.1);
        }
      }
    }
  }

  .error-message {
    margin-top: 6px;
    font-size: 14px;
    color: $error-color;
  }

  &.error {
    .value {
      border-color: $error-color;
    }

    .option-list {
      border-color: $error-color;
    }
  }

  &.success {
    .value {
      border-color: $success-color;
    }
  }
}
