.popup-overlay .popup.offer-popup {
  padding: 28px 38px;

  @media (max-width: 616px) {
    padding: 25px 22px;

    h2 {
      padding: 0 32px;
    }
  }

  .header {
    text-align: center;

    @media (max-width: 616px) {
      text-align: left;
    }

    .provider-name {
      font-weight: bold;
      font-size: 18px;
    }
  }

  .main {
    margin-top: 24px;

    .title {
      margin: 16px 0 10px 0;
      font-weight: bold;
      font-size: 18px;
    }

    .details {
      font-weight: 500;
      font-size: 14px;

      strong {
        margin-left: 10px;
      }
    }
  }

  .footer {
    margin-top: 18px;
    font-size: 14px;
    font-weight: 500;

    p {
      display: flex;
      align-items: center;
      height: 30px;

      strong {
        margin-left: 10px;
      }
    }

    .additional-files-line {
      display: flex;
      align-items: center;
      margin-top: -6px;

      .additional-files {
        display: flex;
      }
    }
  }

  .buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 46px;

    @media (max-width: 616px) {
      flex-direction: column;
    }

    .kredy-button {
      width: 216px;

      @media (max-width: 616px) {
        margin-bottom: 16px;
        width: 100%;
      }
    }

    .button-icon {
      margin-right: 10px;
      width: 14px;
    }
  }
}
